import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";

interface Clue {
  text: string;
  hint: string;
  position: number;
}

interface GameState {
  word: string;
  clues: Clue[];
  currentClueIndex: number;
  isCompleted: boolean;
  isCorrect?: boolean;
}

interface CompletionResponse {
  message: string;
  nextRoundId?: string;
}

type LetterStatus = "correct" | "wrong-position" | "default";

interface LetterState {
  value: string;
  status: LetterStatus;
}

const API_URL = process.env.REACT_APP_API_URL || "http://0.0.0.0:3002";

const Game: React.FC = () => {
  const { roundId } = useParams();
  const navigate = useNavigate();
  const [gameState, setGameState] = useState<GameState | null>(null);
  const [letterInputs, setLetterInputs] = useState<LetterState[]>([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showNextRoundPopup, setShowNextRoundPopup] = useState(false);
  const [nextRoundId, setNextRoundId] = useState<string | null>(null);
  const [showQuitPopup, setShowQuitPopup] = useState(false);
  const [hasGuessed, setHasGuessed] = useState(false);
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchGameState = async () => {
      try {
        const nickname = localStorage.getItem("nickname");
        const response = await fetch(`${API_URL}/api/rounds/${roundId}?nickname=${nickname}`);
        const data = await response.json();
        
        if (data.message === "Round not found") {
          setError("Round not found");
          return;
        }

        setGameState(data);

        // If round is completed, show answer and all clues
        if (data.isCompleted) {
          setLetterInputs(
            data.word.split("").map((letter: string) => ({
              value: letter,
              status: data.isCorrect ? "correct" : "default"
            }))
          );
        } else {
          // Normal initialization for unplayed rounds
          const initialState: LetterState = { value: "", status: "default" };
          setLetterInputs(Array(data.word.length).fill({...initialState}));
        }
      } catch (error) {
        console.error("Error fetching game state:", error);
        setError("Error loading game");
      }
    };

    fetchGameState();
  }, [roundId]);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [gameState]);

useEffect(() => {
    setShowNextRoundPopup(false);
    setNextRoundId(null);
    setMessage("");
    setLetterInputs([]);
  }, [roundId]);

  const checkHints = (inputs: LetterState[]): LetterState[] => {
    if (!gameState?.clues) return inputs;

    const currentClues = gameState.clues.slice(0, gameState.currentClueIndex + 1);
    const word = inputs.map(l => l.value).join("").toLowerCase();

    return inputs.map((letterState, index) => {
      let status: LetterStatus = "default";

      for (const clue of currentClues) {
        if (!clue.hint) continue;
        
        const hint = clue.hint.toLowerCase();
        const hintLength = hint.length;
        const position = clue.position || 0;

        if (index >= position && index < position + hintLength) {
          const wordSlice = word.slice(position, position + hintLength);
          if (wordSlice === hint) {
            status = "correct";
          }
        }

        for (let i = 0; i <= word.length - hintLength; i++) {
          if (i === position) continue;
          const wordSlice = word.slice(i, i + hintLength);
          if (wordSlice === hint && index >= i && index < i + hintLength) {
            status = status === "correct" ? "correct" : "wrong-position";
          }
        }
      }

      return {
        value: letterState.value,
        status
      };
    });
  };



  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !letterInputs[index].value && index > 0) {
      const prevInput = document.querySelector(`input[data-index="${index - 1}"]`) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
  };

const handleLetterChange = (index: number, value: string) => {
  if (!gameState?.word) return;

  const newLetterInputs = [...letterInputs];
  newLetterInputs[index] = { 
    value: value.toUpperCase(),
    status: "default"  // Always default status when typing
  };
  
  setLetterInputs(newLetterInputs);  // Remove checkHints here

  // Always move to next input when a letter is entered
  if (value && index < letterInputs.length - 1) {
    const nextInput = document.querySelector(`input[data-index="${index + 1}"]`) as HTMLInputElement;
    if (nextInput) {
      nextInput.focus();
      nextInput.select();  // Select any existing text
    }
  }
};

const handleGuess = async (e: React.FormEvent) => {
  e.preventDefault();
setHasGuessed(true); 
 if (!gameState) return;

  const guess = letterInputs.map(l => l.value).join("");
  
  // Check hints for all guesses, whether correct or not
  const checkedInputs = checkHints(letterInputs);
  setLetterInputs(checkedInputs);

  if (guess.toLowerCase() === gameState.word.toLowerCase()) {
    setMessage("Correct! You won!");
    try {
      const response = await fetch(`${API_URL}/api/rounds/${roundId}/complete`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nickname: localStorage.getItem("nickname") }),
      });
      const result: CompletionResponse = await response.json();
      
      if (result.nextRoundId) {
        setShowNextRoundPopup(true);
        setNextRoundId(result.nextRoundId);
      } else {
        setShowNextRoundPopup(true);
      }
    } catch (error) {
      console.error("Error marking round complete:", error);
    }
  } else {
    setMessage("Wrong guess. Here is another clue!");
    if (gameState.currentClueIndex < gameState.clues.length - 1) {
      setGameState(prev => 
        prev ? { ...prev, currentClueIndex: prev.currentClueIndex + 1 } : null
      );
    }

// Keep both green and orange letters
  const newInputs = checkedInputs.map(letter => ({
  value: (letter.status === "correct" || letter.status === "wrong-position") ? letter.value : "",
  status: letter.status
}));

    setLetterInputs(newInputs);

  setTimeout(() => {
    const firstInput = document.querySelector('input[data-index="0"]') as HTMLInputElement;
    if (firstInput) {
      firstInput.focus();
      firstInput.select();
    }
  }, 100);
 }

};

  if (error) return <div className="error-message">{error}</div>;
  if (!gameState || !gameState.clues) return <div>Loading...</div>;

  return (
    <div className="game-container">
    <div className="game-header">
      <button 
        className="home-button"
        onClick={() => {
          if (hasGuessed) {
            setShowQuitPopup(true);
          } else {
            navigate('/');
          }
        }}
      >
        ← Home
      </button>
      {gameState?.isCompleted && (
        <div className={`completion-status ${gameState.isCorrect ? 'correct' : 'incorrect'}`}>
          {gameState.isCorrect ? 'Completed Successfully' : 'Completed - Incorrect'}
        </div>
      )}
    </div>

   {showQuitPopup && (
      <div className="popup-overlay">
        <div className="popup">
          <h3>Quit Round?</h3>
          <p>You'll forfeit this round. Are you sure?</p>
          <div className="popup-buttons">
            <button 
              onClick={async () => {
                try {
                  await fetch(`${API_URL}/api/rounds/${roundId}/complete`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ 
                      nickname: localStorage.getItem("nickname"),
                      forfeited: true
                    }),
                  });
                } catch (error) {
                  console.error("Error marking round as forfeited:", error);
                }
                navigate('/');
              }}
              className="quit-button"
            >
              Yes, Quit
            </button>
            <button 
              onClick={() => setShowQuitPopup(false)}
              className="cancel-button"
            >
              No, Continue
            </button>
          </div>
        </div>
      </div>
    )}

    <form onSubmit={handleGuess}>
        <div className="letter-input-container">
          {letterInputs.map((letter, index) => (
            <input
              key={index}
              ref={index === 0 ? firstInputRef : null}
              data-index={index}
              className={`letter-input ${letter.status}`}
              type="text"
              maxLength={1}
              value={letter.value}
              disabled={gameState?.isCompleted}
              onChange={(e) => handleLetterChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              style={{
                backgroundColor: 
                  letter.status === "correct" ? "#4caf50" : 
                  letter.status === "wrong-position" ? "#ff9800" : 
                  "white",
                cursor: gameState?.isCompleted ? "not-allowed" : "text"
              }}
            />
          ))}
        </div>
        {!gameState?.isCompleted && (
          <button type="submit" className="guess-button">Guess</button>
        )}
      </form>

      <div className="clues-container">
        {gameState.clues
          .slice(0, gameState.isCompleted ? undefined : gameState.currentClueIndex + 1)
          .map((clue, index) => (
            <div key={index} className="clue">
              {clue.text}
            </div>
          ))}
      </div>

      {message && <div className="message">{message}</div>}
{showNextRoundPopup && (
  <div className="popup-overlay">
    <div className="popup">
      <h3>Congratulations!</h3>
      <p>You've completed this round!</p>
      <button 
        onClick={() => {
          setShowNextRoundPopup(false);  // Add this line
          if (nextRoundId) {
            navigate(`/play/${nextRoundId}`);
          } else {
            navigate('/');
          }
        }}
        className="next-round-button"
      >
        {nextRoundId ? 'Next Round' : 'Back to Rounds'}
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default Game;
