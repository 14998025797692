import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/main.css';

interface Round {
  id: string;
  roundNumber: number;
  isCompleted: boolean;
}

const API_URL = process.env.REACT_APP_API_URL || "http://0.0.0.0:3002";

const Home: React.FC = () => {
  const [rounds, setRounds] = useState<Round[]>([]);
  const [nickname, setNickname] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const storedNickname = localStorage.getItem('nickname');
    if (storedNickname) {
      setNickname(storedNickname);
      setIsLoggedIn(true);
      fetchRounds();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchRounds = async () => {
    try {
      console.log('Fetching rounds...');
      setLoading(true);
      
      const response = await fetch(`${API_URL}/api/rounds`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Received rounds data:', data);
      
      if (Array.isArray(data)) {
        setRounds(data);
        setError('');
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching rounds:', error);
      setError('Error loading rounds. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (nickname.trim()) {
      localStorage.setItem('nickname', nickname);
      setIsLoggedIn(true);
      fetchRounds();
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-container">
        <h2>Word Clue Game</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Enter your nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className="nickname-input"
          />
          <button type="submit" className="start-button">Start Playing</button>
        </form>
      </div>
    );
  }

  return (
    <div className="home-container">
      <header>
        <h2>Welcome, {nickname}!</h2>
        <button 
          onClick={() => {
            localStorage.removeItem('nickname');
            setIsLoggedIn(false);
            setNickname('');
          }}
          className="logout-button"
        >
          Logout
        </button>
      </header>

      {error && (
        <div className="error-message">
          {error}
          <button onClick={fetchRounds} className="retry-button">
            Retry
          </button>
        </div>
      )}
      
      {loading ? (
        <div className="loading">Loading rounds...</div>
      ) : (
        <div className="rounds-grid">
          {rounds.map((round) => (
            <Link
              key={round.id}
              to={`/play/${round.id}`}
              className={`round-card ${round.isCompleted ? 'completed' : ''}`}
            >
              <div className="round-number">Round {round.roundNumber}</div>
              <div className="round-status">
                {round.isCompleted ? '✓' : 'Play'}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
